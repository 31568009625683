.center
{
	margin-left:auto;
	margin-right:auto;
}
.left_border{
	border-left-color: #6b6eb5;
	border-left-width: 3px;
	border-left-style: solid;
}
.right_border{
	border-right-color: #EFEFF4;
	border-right-width: 1px;
	border-right-style: solid;
}
.bottom_border{
	border-bottom-color: #EFEFF4;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}
.top_border{
	border-top-color: #EFEFF4;
	border-top-width: 1px;
	border-top-style: solid;
}
.bottom_button{
	width:70%;
	position:fixed;
	bottom:0;height:50px;
	background-color:#6b6eb5;
	text-align:center;
	color:white;
	line-height: 50px;
	z-index: 300;
	cursor:pointer;
}
.m_r_img{
	width:30px;
	height:30px;
	border-radius: 50%;
	margin-right: 2px;
}
.p_10{
	padding:10px;
}
.s_r_img{
	width: 16px;
	height: 16px;
	margin-top:1px;
	border-radius: 50%;
	margin-right: 2px;
}
.head{
	width: 70px;
	height: 70px;
	border-radius: 50%;
	z-index: 200;
	position: relative;
}